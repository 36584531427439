// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-blog-js": () => import("../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-company-js": () => import("../src/pages/company.js" /* webpackChunkName: "component---src-pages-company-js" */),
  "component---src-pages-contact-js": () => import("../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-george-js": () => import("../src/pages/george.js" /* webpackChunkName: "component---src-pages-george-js" */),
  "component---src-pages-gifting-js": () => import("../src/pages/gifting.js" /* webpackChunkName: "component---src-pages-gifting-js" */),
  "component---src-pages-gin-js": () => import("../src/pages/gin.js" /* webpackChunkName: "component---src-pages-gin-js" */),
  "component---src-pages-hampers-js": () => import("../src/pages/hampers.js" /* webpackChunkName: "component---src-pages-hampers-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-newsletter-js": () => import("../src/pages/newsletter.js" /* webpackChunkName: "component---src-pages-newsletter-js" */),
  "component---src-pages-privacy-js": () => import("../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-sales-js": () => import("../src/pages/sales.js" /* webpackChunkName: "component---src-pages-sales-js" */),
  "component---src-pages-serves-js": () => import("../src/pages/serves.js" /* webpackChunkName: "component---src-pages-serves-js" */),
  "component---src-pages-serves-ginandtonic-js": () => import("../src/pages/serves/ginandtonic.js" /* webpackChunkName: "component---src-pages-serves-ginandtonic-js" */),
  "component---src-pages-serves-ginbuck-js": () => import("../src/pages/serves/ginbuck.js" /* webpackChunkName: "component---src-pages-serves-ginbuck-js" */),
  "component---src-pages-serves-hankypanky-js": () => import("../src/pages/serves/hankypanky.js" /* webpackChunkName: "component---src-pages-serves-hankypanky-js" */),
  "component---src-pages-serves-hotpunch-js": () => import("../src/pages/serves/hotpunch.js" /* webpackChunkName: "component---src-pages-serves-hotpunch-js" */),
  "component---src-pages-serves-martini-js": () => import("../src/pages/serves/martini.js" /* webpackChunkName: "component---src-pages-serves-martini-js" */),
  "component---src-pages-serves-neat-js": () => import("../src/pages/serves/neat.js" /* webpackChunkName: "component---src-pages-serves-neat-js" */),
  "component---src-pages-stockists-js": () => import("../src/pages/stockists.js" /* webpackChunkName: "component---src-pages-stockists-js" */)
}

